import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
	DeviceListLevel,
	MediaPermissions,
	MediaTypes,
	UserRoles,
	AiAlertActivityType,
	LedColors,
	HealthcareErrorCode,
	EventSubscriberRoomType,
	ObservationType,
	ConditionType,
	PrecautionTriggerValues,
} from 'constants/enums.js';
import Grid from 'components/Grid.jsx';
import MainLayout from 'views/Layouts/MainLayout.jsx';
import translate from 'i18n-translations/translate.jsx';
import classNames from 'classnames';
import AlertFeed from 'components/AlertFeedMayo.jsx';
import Alert from 'components/Alert.jsx';
import Notification from 'components/Notification.jsx';
import { Prompt } from 'react-router-dom';
import AmbientMonitoringAlertModal from 'calls/components/AmbientMonitoringAlertModal.jsx';
import _ from 'lodash';
import {
	checkForPermission,
	findSectorById,
	findDeviceById,
	getConfigurationValue,
	updateConfigsList,
	getStorage,
	isValidJSON,
	getPreferredLanguageLocale,
} from 'infrastructure/helpers/commonHelpers.js';
import { getDeviceList } from 'api/devices.js';
import { getDeviceOwnerPatientWithRetry, getPatientByUserId } from 'api/patients.js';
import { healthCareCdnUrl } from 'constants/global-variables.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import SocketEvents from 'constants/socket-events.js';
import { getUserRole } from 'infrastructure/auth.js';
import { getTeams } from 'api/teams.js';
import { forwardAiAlert } from 'api/alerts.js';
import {
	MonitoringSettings,
	SettingsCategory,
	UserSettingTypes,
	configurableMonitoringMenu,
} from 'constants/configurationEnums.js';
import { getRoomSettings } from 'api/adminConfigurations.js';
import { getUserPreferences } from 'api/users.js';
import useScreenType from 'hooks/useScreenType.js';
import AndroidSocket from 'infrastructure/socket-client/AndroidInterface.jsx';
import { getHealthSystemSubTree } from 'api/healthSystems.js';
import NSTUnassigned from 'components/NSTUnassigned.jsx';
import { subscribeToAlerts } from 'api/alert-center.js';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { getPrecautionObservations, getPrecautions } from 'api/monitoring.js';
import { getActiveEncounter, getPrecautions as getPrecautionsEhr } from 'api/whiteboard.js';
import { PrecautionsObservationCodes } from 'constants/precautions.js';
import NSTBrowserWarning from 'components/NSTBrowserWarning.jsx';
import { LOCALES } from 'i18n-translations/locales.js';

const getLanguagePrefix = () => {
	let result = '';
	const locale = getPreferredLanguageLocale();
	if (locale === LOCALES.ARABIC) {
		result = 'arabic-';
	}
	if (locale === LOCALES.GERMAN) {
		result = 'german-';
	}
	if (locale === LOCALES.SPANISH) {
		result = 'spanish-';
	}
	return result;
};

const initialPrecautions = [
	{
		id: '22631001',
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#F3C752',
	},
	{
		id: '77272004',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#E270DA',
	},
	{
		id: '441862004',
		abbreviation: 'ISO',
		name: 'Isolation',
		textColor: '#FFFFFF',
		boxColor: '#D64F2D',
	},
	{
		id: '413322009',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#282D30',
		boxColor: '#88D9FB',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
	{
		id: '49436004',
		abbreviation: 'HP',
		name: 'Hospice',
		textColor: '#FFFFFF',
		boxColor: '#000000',
	},
	{
		id: '71388002',
		abbreviation: 'D',
		name: 'Detox',
		textColor: '#282D30',
		boxColor: '#F2A356',
	},
	{
		id: '233604007',
		abbreviation: 'PO2',
		name: 'Pulling O2',
		textColor: '#282D30',
		boxColor: '#B6D7E4',
	},
	{
		id: '35489007',
		abbreviation: 'IC',
		name: 'Impulsive/Confused',
		textColor: '#282D30',
		boxColor: '#D3D3D3',
	},
	{
		id: '43998006',
		abbreviation: 'NR',
		name: 'Non-redirectable',
		textColor: '#FFFFFF',
		boxColor: '#4BA5F8',
	},
];
const ehrPrecautions = [
	{
		id: PrecautionsObservationCodes.FALLS_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.FALL_RISK_SCORE_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.FALL_RISK_SCALE_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.IBED_FALL_RISK_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
		isFallPrecaution: true,
	},
	{
		id: PrecautionsObservationCodes.ORDER,
		abbreviation: 'F',
		name: 'Falls',
		textColor: '#282D30',
		boxColor: '#FFD600',
		icon: 'fall.svg',
	},
	{
		id: 'PRE2',
		abbreviation: 'SZ',
		name: 'Seizure',
		textColor: '#FFFFFF',
		boxColor: '#FFD600',
		icon: 'seizure.svg',
	},
	{
		id: 'PRE3',
		abbreviation: 'SW',
		name: 'Suicide Watch',
		textColor: '#fff',
		boxColor: '#500772',
		icon: 'suicide.svg',
	},
	{
		id: 'PRE6',
		abbreviation: 'AS',
		name: 'Aspiration',
		textColor: '#fff',
		boxColor: '#FFD600',
		icon: 'aspiration.svg',
	},
	{
		id: '140133578',
		abbreviation: 'BSP',
		name: 'Behavioral Safety Plan',
		textColor: '#fff',
		boxColor: '#7B2D9F',
		icon: 'behavioral.svg',
	},
	{
		id: '26544005',
		abbreviation: 'SB',
		name: 'Suspicious Behavior',
		textColor: '#FFFFFF',
		boxColor: '#7B35C1',
	},
];

const AlertCenter = () => {
	const healthSystems = useSelector(state => state.healthSystems);
	const user = useSelector(state => state.user);
	const [alertFeeds, setAlertFeeds] = useState([]);
	const [isSessionNotificationVisible, setIsSessionNotificationVisible] = useState(false);
	const [error, setError] = useState('');
	const [selectedFeed, setSelectedFeed] = useState(null);
	const [isAmbientMonitoringAlertModalOpen, setIsAmbientMonitoringAlertModalOpen] = useState(false);
	const [channels, setChannels] = useState([]);
	const intl = useIntl();
	const dispatch = useDispatch();
	const micStatus = useRef(null);
	const playingAlert = useRef(new Audio());
	const [showForwardToNurses, setShowForwardToNurses] = useState(false);
	const [adminConfigurations, setAdminConfigurations] = useState(configurableMonitoringMenu(getUserRole()));
	const screenType = useScreenType();
	const { current: socket } = useRef(new AndroidSocket());
	const [deviceInformation, setDeviceInformation] = useState(null);
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));

	useEffect(() => {
		const deviceInfo = socket.getDeviceInfo();

		if (deviceInfo && isValidJSON(deviceInfo)) {
			let parsedInfo = JSON.parse(deviceInfo);
			setDeviceInformation(parsedInfo);
		}
	}, []);

	useEffect(() => {
		if (deviceInformation) {
			window.Android.subscribeToHSEventsAck = data => {
				console.log(data);
			};

			const payload = {
				healthSystemId: deviceInformation.healthSystemId,
				rooms: [
					EventSubscriberRoomType.AI_EVENTS,
					EventSubscriberRoomType.USER_STATUS,
					EventSubscriberRoomType.DEVICE_STATUS,
					EventSubscriberRoomType.PATIENT_EVENTS,
					EventSubscriberRoomType.NURSE_STATION_EVENTS,
				],
			};

			socket.emitWithAck(SocketEvents.Client.SUBSCRIBE_TO_HS_EVENTS, 'subscribeToHSEventsAck', JSON.stringify(payload));
			const arr = [];
			alertFeeds.forEach(feed => {
				let roomExists = deviceInformation.rooms.find(helloDeviceId => feed?.deviceId == helloDeviceId);

				if (!roomExists && feed?.deviceId) {
					arr.push(feed.deviceId);
				}
			});
			removeFeeds(arr);
			if (deviceInformation.rooms.length > 0) {
				deviceInformation.rooms.forEach(room => onAddDevice(room));
			}
		}
	}, [deviceInformation]);

	if (window.Android) {
		window.Android.roomsUpdated = data => {
			if (isValidJSON(data) && deviceInformation) {
				let tempInfo = _.cloneDeep(deviceInformation);
				tempInfo.rooms = JSON.parse(data);
				setDeviceInformation(tempInfo);
			}
		};

		window.Android.deviceInfoUpdated = prop => {
			if (isValidJSON(prop)) {
				let data = JSON.parse(prop);
				let newDeviceInformation = _.cloneDeep(deviceInformation);
				newDeviceInformation.companyId = data.companyId;
				newDeviceInformation.healthSystemId = data.healthSystemId;
				newDeviceInformation.regionId = data.regionId;
				setDeviceInformation(newDeviceInformation);
			}
		};
	}

	useEffect(() => {
		const toggleIsFullScreen = () => {
			dispatch(healthSystemsActionCreators.toggleFullscreen());
		};

		document.addEventListener('fullscreenchange', toggleIsFullScreen);
		return () => {
			document.removeEventListener('fullscreenchange', toggleIsFullScreen);
		};
	}, [dispatch]);

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			healthSystemsActionCreators.setStreamPermissionMessage(null);
		}
	};

	useEffect(() => {
		if (alertFeeds.find(item => item.warning?.value) && !alertFeeds.find(item => item.isStatAlarmActive)) {
			const soundUrl = `${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`;

			if (!playingAlert.current?.src || playingAlert.current?.src !== soundUrl) {
				playingAlert.current.src = `${healthCareCdnUrl}sound/${getLanguagePrefix()}patient-at-risk.mp3`;
				playingAlert.current.loop = true;
				// playingAlert.current.load();
				playingAlert.current.play().catch(e => console.error('Error playing audio:', e));
			}
		}
	}, [alertFeeds]);

	useEffect(() => {
		if (window.Android) {
			window.Android.onAiAlertActivity = data => {
				if (!data || data?.aiAlertActivityTypeId !== AiAlertActivityType.ADDED_AS_HOSPITAL_CARETAKER) {
					return;
				}
				const newVideoFeeds = alertFeeds.map(item => {
					const newItem = { ...item };
					if (newItem.deviceId === data.deviceId && newItem.intervention) {
						newItem.intervention.value = true;
						newItem.intervention.providerFullName = data.causerFullName;
						newItem.intervention.providerProfilePicture = data.causerProfilePicture;
					}
					return newItem;
				});
				setAlertFeeds(newVideoFeeds);
			};
		}
		socket.on(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY, 'onAiAlertActivity');
		return () => {
			socket.off(SocketEvents.Alerts.NEW_AI_ALERT_ACTIVITY);
		};
	}, [socket, alertFeeds]);

	useEffect(() => {
		if (window.Android) {
			window.Android.onStatAlarm = data => {
				if (isValidJSON(data)) {
					let result = JSON.parse(data);
					if (alertFeeds.find(item => item.deviceId === result.deviceId) && result.isSuccessful) {
						const newAlertFeeds = alertFeeds.map(item => {
							const newItem = { ...item };
							if (item.deviceId === result.deviceId) {
								newItem.isStatAlarmActive = result.data && result.shouldForwardToNurseStation;
								if (result.data) {
									newItem.warning = {};
								}
							}
							return newItem;
						});

						const shouldPlay = playingAlert?.current && result.data && result.shouldForwardToNurseStation;
						const shouldPause = playingAlert?.current && !result.data && !newAlertFeeds.find(item => item.isStatAlarmActive);

						if (!playingAlert?.current) {
							playingAlert.current = new Audio();
						}

						if (shouldPlay) {
							playingAlert.current.src = `${healthCareCdnUrl}admin/super-admin/stat-alarms/alarm-1.mp3`;
							playingAlert.current.pause();
							playingAlert.current.currentTime = 0;
							playingAlert.current.loop = true;
							playingAlert.current.play().catch(e => console.error('Error playing audio:', e));
						}

						if (shouldPause) {
							playingAlert.current.currentTime = 0;
							playingAlert.current.loop = false;
							playingAlert.current.pause();
						}
						setAlertFeeds(newAlertFeeds);
					}
				}
			};
		}
		socket.on(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE, 'onStatAlarm');
		return () => {
			socket.off(SocketEvents.Conference.ALERT_PATIENT_AT_RISK_RESPONSE);
		};
	}, [alertFeeds, socket]);

	useEffect(() => {
		if (window.Android) {
			window.Android.onPatientAiSettingsUpdated = data => {
				if (isValidJSON(data)) {
					const { deviceId, patientAiSettings } = JSON.parse(data);
					if (!alertFeeds.find(item => item.deviceId === deviceId)) {
						return;
					}
					setAiSettingsAction({ deviceId, settings: patientAiSettings });
				}
			};
		}

		socket.on(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED, 'onPatientAiSettingsUpdated');
		return () => {
			socket.off(SocketEvents.Alerts.PATIENT_AI_SETTINGS_UPDATED);
		};
	}, [alertFeeds, dispatch, socket]);

	useEffect(() => {
		const currentAudio = playingAlert.current;
		return () => {
			if (currentAudio) {
				currentAudio.pause();
				currentAudio.currentTime = 0;
			}
		};
	}, []);

	useEffect(() => {
		const getMicStatus = async () => {
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			micStatus.current.onchange = onDevicePermissionChange;
		};
		getMicStatus();
	}, []);

	useEffect(() => {
		fetchTeams();
	}, []);

	const stopVoiceOver = (type, deviceId) => {
		const newFeeds = alertFeeds.map(item => {
			const newItem = item;
			if (newItem.deviceId === deviceId && type === newItem.warning.type) {
				newItem.warning = {};
				newItem.isStatAlarmActive = false;
			}
			return newItem;
		});
		if (!newFeeds.find(item => item.warning?.type || item.isStatAlarmActive)) {
			if (playingAlert?.current) {
				playingAlert.current.pause();
				playingAlert.current.currentTime = 0;
				playingAlert.current.src = '';
				playingAlert.current = null;
				playingAlert.current = new Audio();
			}
		}
		setAlertFeeds(newFeeds);
	};

	const getMappedFeed = async (deviceId, isFromSession = false, hasPatientChanged = false) => {
		if (!deviceInformation) {
			return null;
		}
		const treeData = await getHealthSystemSubTree(deviceInformation.healthSystemId, deviceInformation.companyId);
		if (treeData?.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return null;
		}
		const sector = findSectorById(treeData, deviceId);
		if (!sector || (!isFromSession && !hasPatientChanged && alertFeeds.some(feed => feed.deviceId === deviceId))) {
			return null;
		}
		const { id, hospitalId, departmentId, floorId, name, aiPrivacyStatus } = sector;
		return {
			deviceId,
			events: [],
			warning: {},
			roomName: name,
			roomId: id,
			hospitalId,
			departmentId,
			floorId,
			deviceOwner: {},
			aiPrivacyStatus,
			hospitalName: name,
			lastCondition: { code: '', display: '', risk: '' },
			alertInfo: {},
			healthSystemId: user.userSession.healthSystem.id,
			intervention: {},
			precautions: [],
			isPatientFetching: true,
		};
	};

	const getTypeOfFallPrecaution = (precaution, code) =>
		precaution?.code === code && precaution?.observationTypeId === ObservationType.BRADEN_SCORE;

	const getActiveItem = precaution => {
		const isFallPrecaution =
			[PrecautionsObservationCodes.FALLS_CODE, PrecautionsObservationCodes.FALL_RISK_SCORE_CODE].includes(precaution?.code) &&
			precaution?.observationTypeId === ObservationType.BRADEN_SCORE;

		if (isFallPrecaution) {
			return (precaution?.valueString || precaution?.value) && (+precaution?.valueString >= 7 || +precaution?.value >= 7);
		}
		if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.IBED_FALL_RISK_CODE)) {
			return (
				(precaution?.valueString || precaution?.value) &&
				precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.IBED_FALL_RISK.toLowerCase()
			);
		}
		if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.HUMPTY_DUMPTY_CODE)) {
			return (
				(precaution?.valueString || precaution?.value) &&
				precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.PED_FALL_RISK.toLowerCase()
			);
		}
		if (getTypeOfFallPrecaution(precaution, PrecautionsObservationCodes.FALL_RISK_SCALE_CODE)) {
			return (
				(precaution?.valueString || precaution?.value) &&
				precaution?.valueString?.toLowerCase() === PrecautionTriggerValues.FALL_RISK_SCALE.toLowerCase()
			);
		}
		return !!precaution;
	};

	const getUpdatedPrecautionData = (fyiFlag, isEhrField, arr, condition, observation) => {
		return arr.map(item => {
			let foundPrecautions = [];
			let foundPrecaution = null;
			if (isEhrField) {
				foundPrecautions = observation.observations.filter(p => p.code === item.id);
				if (foundPrecautions?.length > 0) {
					foundPrecaution = foundPrecautions.reduce((latest, current) => {
						const latestDate = new Date(latest.effectiveDateTime);
						const currentDate = new Date(current.effectiveDateTime);
						return currentDate > latestDate ? current : latest;
					}, foundPrecautions[0]);
				}
			}
			const precaution = condition.conditions.find(p => p.code === item.id) || (isEhrField && foundPrecaution);
			const violentPatient = '140133578';
			return {
				...item,
				active: isEhrField && item.id === violentPatient ? fyiFlag : getActiveItem(precaution),
				effectiveDateTime: precaution?.effectiveDateTime,
			};
		});
	};

	useEffect(() => {
		if (window.Android) {
			window.Android.onOwnerUpdated = async prop => {
				if (isValidJSON(prop) && deviceInformation) {
					let data = JSON.parse(prop);
					const found = alertFeeds.find(item => item.deviceId === data.solHelloDeviceId);
					if (found?.deviceOwner?.healthcareUserId === data.ownerId) {
						return;
					}

					resetPatientData(data.solHelloDeviceId);
				}
			};
		}

		socket.on(SocketEvents.HelloDevice.DEVICE_LOCATION_OWNER_UPDATED, 'onOwnerUpdated');

		return () => {
			socket.off(SocketEvents.HelloDevice.DEVICE_LOCATION_OWNER_UPDATED);
		};
	}, [socket, deviceInformation, alertFeeds]);

	const resetPatientData = deviceId => {
		setAiSettingsAction({
			deviceId,
			settings: [],
		});
		const newFeeds = resetFeedToInitialState(deviceId);
		setAlertFeeds(newFeeds);
		const feed = newFeeds.find(item => item?.deviceId === deviceId);
		if (feed) {
			setDeviceUpdatedInformation(feed, deviceId);
		}
	};

	const resetFeedToInitialState = deviceId => {
		const newFeeds = alertFeeds.map(item => {
			if (item.deviceId === deviceId) {
				const newItem = {
					deviceId,
					events: [],
					warning: {},
					roomName: item.roomName,
					roomId: item.roomId,
					hospitalId: item.hospitalId,
					departmentId: item.departmentId,
					floorId: item.floorId,
					deviceOwner: {},
					hospitalName: item.hospitalName,
					lastCondition: {
						code: '',
						display: '',
						risk: '',
					},
					alertInfo: {},
					healthSystemId: null,
					intervention: {},
					precautions: [],
					isDefaultOwner: false,
					isPrecautionsEhrField: false,
				};

				return newItem;
			}
			return item;
		});

		return newFeeds;
	};

	const getLatestPrecautions = async (fyiFlag, encounterId, healthcareUserId, isEhrField) => {
		const [conditionsRes, observationsRes] = await Promise.all([
			getPrecautionsEhr(healthcareUserId, encounterId),
			getPrecautionObservations(healthcareUserId, encounterId),
		]);
		if (conditionsRes.error || observationsRes.error) {
			setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			return [];
		} else {
			return getUpdatedPrecautionData(fyiFlag, isEhrField, ehrPrecautions, conditionsRes, observationsRes);
		}
	};

	const setDeviceUpdatedInformation = async (feed, helloDeviceId) => {
		if (!deviceInformation) {
			return;
		}
		const newFeed = { ...feed };
		let isDefaultOwner = false;
		const devicesResponse = await getDeviceList(deviceInformation?.companyId, DeviceListLevel.ROOM, newFeed?.roomId);
		if (devicesResponse.error) {
			setError(devicesResponse.error.message);
		} else {
			isDefaultOwner = devicesResponse[0].isDefaultOwner;
		}
		if (!isDefaultOwner) {
			let deviceOwner = {};
			const deviceOwnerResponse = await getDeviceOwnerPatientWithRetry(deviceInformation?.companyId, helloDeviceId);
			if (deviceOwnerResponse.error) {
				setError(deviceOwnerResponse.error.message);
			} else {
				deviceOwner = deviceOwnerResponse;
				newFeed.deviceOwner = deviceOwner;
			}
		}

		newFeed.isDefaultOwner = isDefaultOwner;
		const subscribeResponse = await subscribeToAlerts(deviceInformation?.deviceId);

		if (subscribeResponse.error) {
			if (subscribeResponse.error.response.data.code === HealthcareErrorCode.MAX_AMBIENT_MONITORING_PROVIDERS) {
				setError(intl.formatMessage({ id: 'maxAmbientMonitoringProviders' }));
			} else {
				setError(intl.formatMessage({ id: 'somethingWentWrong' }));
			}
			return;
		}

		const deviceSettings = subscribeResponse.nurseStationSubscribedDevicePatientAiSettings.find(
			item => item?.deviceId === newFeed.deviceId
		);

		if (deviceSettings) {
			const enabledSettings = deviceSettings.patientAiSettings.filter(setting => setting.isEnabled);
			if (enabledSettings.length > 0) {
				const mappedResponse = {
					deviceId: newFeed?.deviceId,
					settings: enabledSettings,
				};

				dispatch(aiSettingsActionCreators.setPatientAiSettings(mappedResponse));
			}
		}

		newFeed.isDefaultOwner = isDefaultOwner;

		if (!isDefaultOwner && newFeed.deviceOwner?.healthcareUserId) {
			const adminRoomSettings = await getRoomSettings(deviceInformation?.companyId, newFeed?.roomId, SettingsCategory.MONITORING);

			if (adminRoomSettings.error) {
				setError(adminRoomSettings.error.message);
			} else {
				const isEhrField =
					adminRoomSettings.settings.find(item => item.settingTypeId === MonitoringSettings.Precautions)?.value === 'true' &&
					adminRoomSettings.settings.find(item => item.settingTypeId === MonitoringSettings.Precautions)?.variant ===
						'ehr-patient-data-load';

				newFeed.isPrecautionsEhrField = isEhrField;

				if (isEhrField) {
					const [encounterIdRes, patientBasicInformation] = await Promise.all([
						getActiveEncounter(deviceInformation?.companyId, newFeed?.deviceOwner?.healthcareUserId),
						getPatientByUserId(deviceInformation?.companyId, newFeed.deviceOwner?.userId),
					]);
					if (encounterIdRes.error || patientBasicInformation.error) {
						setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					} else {
						newFeed.precautions = await getLatestPrecautions(
							patientBasicInformation?.patient?.fyiFlag === 1,
							encounterIdRes?.encounter?.id,
							newFeed.deviceOwner?.healthcareUserId,
							isEhrField
						);
						newFeed.encounterId = encounterIdRes?.encounter?.id;
					}
				} else {
					const response = await getPrecautions(newFeed.deviceOwner.healthcareUserId);
					if (response.error) {
						setError(intl.formatMessage({ id: 'somethingWentWrong' }));
					} else {
						newFeed.precautions = getUpdatedPrecautionData(false, isEhrField, initialPrecautions, response);
					}
				}
			}
		}
		newFeed.isPatientFetching = false;
		setAlertFeeds(prevState => {
			const alreadyExists = prevState.findIndex(existingFeed => existingFeed?.deviceId === newFeed?.deviceId);
			if (alreadyExists !== -1) {
				return prevState.map((existingFeed, index) => (index === alreadyExists ? { ...existingFeed, ...newFeed } : existingFeed));
			}
			return [...prevState];
		});
	};

	const onAddDevice = async selection => {
		const helloDeviceId = selection;
		const selectedFeed = alertFeeds.find(item => item?.helloDeviceId === helloDeviceId);
		if (selectedFeed) {
			return;
		}
		const feed = await getMappedFeed(helloDeviceId);
		if (feed) {
			setAlertFeeds(prevState => {
				const alreadyExists = prevState.some(existingFeed => existingFeed?.deviceId === feed?.deviceId);
				return alreadyExists ? prevState : [...prevState, feed];
			});
			setDeviceUpdatedInformation(feed, helloDeviceId);
		}
	};

	useEffect(() => {
		if (window.Android) {
			window.Android.handleConditionAdded = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);
					if (!found || !data.deviceId || !data.conditions || data.deviceId !== found?.deviceId || found?.isPatientFetching) {
						return;
					}
					if (!found.isPrecautionsEhrField) {
						let newPrecautions = _.cloneDeep(initialPrecautions);
						if (data.conditions.length === 0) {
							const newAlertFeeds = alertFeeds.map(item => {
								const newItem = { ...item };
								if (item.deviceId === data.deviceId) {
									newItem.precautions = newPrecautions;
								}
								return newItem;
							});
							setAlertFeeds(newAlertFeeds);
							return;
						}
						if (
							data.conditions.length > 0 &&
							data.conditions.filter(item => item.conditionType === ConditionType.PRECAUTION).length === 0
						) {
							return;
						}
						newPrecautions = _.cloneDeep(initialPrecautions).map(precaution => {
							const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
							if (foundPrecaution) {
								precaution.active = true;
							}
							return precaution;
						});
						const newAlertFeeds = alertFeeds.map(item => {
							const newItem = { ...item };
							if (item.deviceId === data.deviceId) {
								newItem.precautions = newPrecautions;
							}
							return newItem;
						});
						setAlertFeeds(newAlertFeeds);
						return;
					}

					const newAlertFeeds = alertFeeds.map(item => {
						const newItem = { ...item };
						if (item.deviceId === data.deviceId) {
							const newPrecautions = ehrPrecautions.map(precaution => {
								const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
								const newPrecaution = { ...precaution };
								if (foundPrecaution) {
									newPrecaution.active = true;
									return newPrecaution;
								} else {
									const foundOtherPrecautions = newItem.precautions.find(x => x.id === precaution.id);
									if (foundOtherPrecautions) {
										newPrecaution.active = !!foundOtherPrecautions?.active;
										newPrecaution.effectiveDateTime = foundOtherPrecautions?.effectiveDateTime;
									}
									return newPrecaution;
								}
							});
							newItem.precautions = newPrecautions;
						}
						return newItem;
					});
					setAlertFeeds(newAlertFeeds);
				}
			};
		}

		if (window.Android) {
			window.Android.handleConditionRemoved = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);

					if (
						!found ||
						!data.deviceId ||
						!data.conditions ||
						data.deviceId !== found?.deviceId ||
						!found?.isPrecautionsEhrField ||
						found?.isPatientFetching
					) {
						return;
					}

					const newAlertFeeds = alertFeeds.map(item => {
						const newItem = { ...item };
						if (item.deviceId === data.deviceId) {
							const newPrecautions = newItem.precautions.map(precaution => {
								const foundPrecaution = data.conditions.find(condition => condition.code === precaution.id);
								if (foundPrecaution && precaution?.active) {
									const newPrecaution = { ...precaution };
									newPrecaution.active = false;
									return newPrecaution;
								}
								return precaution;
							});
							newItem.precautions = newPrecautions;
						}
						return newItem;
					});
					setAlertFeeds(newAlertFeeds);
				}
			};
		}

		const getEhrPrecautions = async (found, data) => {
			const patientBasicInformation = await getPatientByUserId(deviceInformation?.companyId, found.deviceOwner?.userId);
			let fyiFlag = false;
			if (!patientBasicInformation?.error) {
				fyiFlag = patientBasicInformation?.patient?.fyiFlag === 1;
			}
			const precautions = await getLatestPrecautions(
				fyiFlag,
				found.encounterId,
				found.deviceOwner?.healthcareUserId,
				found.isPrecautionsEhrField
			);
			const newAlertFeeds = alertFeeds.map(item => {
				const newItem = { ...item };
				if (item.deviceId === data.deviceId) {
					newItem.precautions = precautions;
				}
				return newItem;
			});
			setAlertFeeds(newAlertFeeds);
		};

		if (window.Android) {
			const hasMatchingCode = data =>
				data.observations.some(observation => ehrPrecautions.some(precaution => precaution.id === observation.code));

			const shouldNotUpdateObservation = (data, found) =>
				!found ||
				found?.deviceId !== data.deviceId ||
				!found.isPrecautionsEhrField ||
				found.isDefaultOwner ||
				found.isPatientFetching ||
				!found?.deviceOwner?.healthcareUserId ||
				!hasMatchingCode(data);

			window.Android.handleObservationsAdded = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);
					if (shouldNotUpdateObservation(data, found)) {
						return;
					}
					getEhrPrecautions(found, data);
				}
			};

			window.Android.handleObservationsRemoved = condition => {
				let data;
				if (isValidJSON(condition)) {
					data = JSON.parse(condition);
					const found = alertFeeds.find(item => item.deviceId === data.deviceId);
					if (shouldNotUpdateObservation(data, found)) {
						return;
					}
					getEhrPrecautions(found, data);
				}
			};
		}

		if (window.Android) {
			window.Android.handleProfileUpdated = prop => {
				let data;
				if (isValidJSON(prop)) {
					data = JSON.parse(prop);
					const violentPatient = '140133578';
					const feed = alertFeeds.find(item => item.deviceOwner?.userId === data.userId);
					if (!feed || !data?.userId || feed.deviceOwner?.userId !== data.userId) {
						return;
					}
					if (data.fyiFlag !== feed.precautions.find(item => item.id === violentPatient)?.active) {
						setAlertFeeds(prevState => {
							return prevState.map(item => {
								if (item.deviceOwner?.userId === data.userId) {
									const newItem = { ...item };
									newItem.precautions = newItem.precautions.map(p => {
										if (p.id === violentPatient) {
											p.active = data.fyiFlag === 1;
										}
										return p;
									});
									return newItem;
								}
								return item;
							});
						});
					}
				}
			};
		}

		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED, 'handleObservationsAdded');
		socket.on(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED, 'handleObservationsRemoved');
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED, 'handleConditionAdded');
		socket.on(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED, 'handleConditionRemoved');
		socket.on(SocketEvents.HealthCare.PATIENT_PROFILE_UPDATED, 'handleProfileUpdated');

		return () => {
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_ADDED);
			socket.off(SocketEvents.HealthCare.PATIENT_OBSERVATIONS_REMOVED);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_ADDED);
			socket.off(SocketEvents.HealthCare.PATIENT_CONDITIONS_REMOVED);
		};
	}, [socket, alertFeeds, intl, adminConfigurations]);

	const fetchTeams = async () => {
		if (
			![UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.VIRTUAL_SITTER].includes(getUserRole()) ||
			!user.userSession.healthSystem.id
		) {
			return;
		}
		const response = await getTeams(user.userSession.healthSystem.id);
		if (!response.error) {
			let channels = [];
			response.teams.forEach(team => {
				channels = channels.concat(team.channels.map(channel => ({ ...channel, team })));
			});
			setChannels(channels);
		}
	};

	const getSelectedFloor = deviceId => {
		const newChannels = [...channels];
		const newTree = [...healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		return newChannels.find(channel => channel.floorId === device?.floorId);
	};

	const getHierarchyNaming = deviceId => {
		const newTree = [...healthSystems.treeData.tree];
		const device = findDeviceById(newTree, deviceId);
		if (!device?.breadcrumb) {
			return {};
		}
		const { breadcrumb } = device;
		return {
			hospital: breadcrumb[0].name,
			department: breadcrumb[1]?.name,
			floor: breadcrumb[2]?.name,
			room: breadcrumb[3]?.name,
		};
	};

	const sendAutomaticAlert = async feed => {
		const conversationId = getSelectedFloor(feed.deviceId)?.conversationId;
		const { hospital, department, floor, room } = getHierarchyNaming(feed.deviceId);
		const dataToSend = {
			conversationId,
			alertId: feed.warning.alertId,
			hospital,
			department,
			floor,
			room,
		};
		let response = null;
		if (feed.warning.isAiAlert) {
			response = await forwardAiAlert(dataToSend);
		}
		if (response.error) {
			setError(response.error.message);
		}
		onAlertCloseClick(feed);
	};

	useEffect(() => {
		if (isSessionNotificationVisible) {
			setTimeout(() => {
				setIsSessionNotificationVisible(false);
			}, 2000);
		}
	}, [isSessionNotificationVisible]);

	useEffect(() => {
		if (error === intl.formatMessage({ id: 'maxAmbientMonitoringProviders' })) {
			setTimeout(() => {
				setError('');
			}, 3000);
		}
	}, [error, intl]);

	useEffect(() => {
		if (window.Android) {
			if (alertFeeds?.find(item => item.isStatAlarmActive)) {
				window.Android?.toggleAlertLed(LedColors.RED);
				return;
			}
			window.Android?.toggleAlertLed(LedColors.OFF);
		}
	}, [alertFeeds]);

	const handleManageAlertClick = feed => {
		setSelectedFeed(feed);
		setIsAmbientMonitoringAlertModalOpen(true);
	};

	const onAlertCloseClick = feed => {
		setIsAmbientMonitoringAlertModalOpen(false);

		if (feed.warning.value && feed.warning.type) {
			stopVoiceOver(feed.warning.type, feed.deviceId);
		}
	};

	const handleProviderIntervening = deviceId => {
		if (!alertFeeds.find(item => item.deviceId === deviceId)) {
			return;
		}
		const newAlertFeeds = alertFeeds.map(item => {
			const newItem = { ...item };
			if (item.deviceId === deviceId) {
				newItem.intervention = {};
			}
			return newItem;
		});
		setAlertFeeds(newAlertFeeds);
	};

	useEffect(() => {
		const getForwardToNursesValue = () => {
			setShowForwardToNurses(getConfigurationValue(adminConfigurations[MonitoringSettings.ForwardToNurses]));
		};
		getForwardToNursesValue();
	}, [adminConfigurations]);

	useEffect(() => {
		if (selectedFeed && isAmbientMonitoringAlertModalOpen) {
			const fetchRoomSettings = async () => {
				const [adminRoomSettings, myRoomSettings] = await Promise.all([
					getRoomSettings(deviceInformation?.companyId, selectedFeed?.roomId, SettingsCategory.MONITORING),
					getUserPreferences(UserSettingTypes.Monitoring, selectedFeed.roomId),
				]);
				const response = updateConfigsList(adminRoomSettings, myRoomSettings);
				if (response.error) {
					setError(response.error.message);
				} else {
					setAdminConfigurations(response.configs);
				}
			};
			fetchRoomSettings();
		}
	}, [selectedFeed, isAmbientMonitoringAlertModalOpen]);

	const removeFeeds = async keys => {
		let feeds = _.cloneDeep(alertFeeds);
		let removedFeeds = feeds.filter(feed => keys.includes(feed.deviceId));
		removedFeeds.forEach(removedFeed => {
			stopVoiceOver(removedFeed.warning.type, removedFeed.deviceId);
		});

		setAlertFeeds(feeds.filter(feed => !keys.includes(feed.deviceId)));
	};

	return (
		<MainLayout>
			{!deviceInformation && <NSTBrowserWarning />}
			{
				<>
					<Grid
						className={classNames(
							'monitoring-grid ambient-monitoring-grid',
							screenType.isSmall ? 'mobile-monitoring-grid' : '',
							healthSystems.isLeftNavigationExpanded ? 'collapsed-second-column-pilot' : ''
						)}
						stretch='100%'>
						<Grid stretch='100%'>
							<main className='main-view alert-center-view'>
								<section>
									{alertFeeds.length === 0 && (
										<>
											<NSTUnassigned />
										</>
									)}
									{alertFeeds.length > 0 && (
										<>
											<Grid width='100%' gridGap='10px' className={classNames('alert-feeds', `feeds-${alertFeeds.length}`)}>
												{alertFeeds
													.sort((a, b) => a.roomName.localeCompare(b.roomName, undefined, { numeric: true }))
													.map(feed => (
														<React.Fragment key={feed.deviceId}>
															<AlertFeed
																feed={feed}
																intl={intl}
																onManageAlertClick={handleManageAlertClick}
																onProviderIntervening={handleProviderIntervening}
																isDefaultOwner={feed?.isDefaultOwner}
																setDiscontinueMonitoringReasons={() => null}
																deviceInformation={deviceInformation}
															/>
														</React.Fragment>
													))}
											</Grid>
										</>
									)}
								</section>
							</main>
						</Grid>
						<Prompt when={alertFeeds.length > 0} message={intl.formatMessage({ id: 'switchingToOtherTab' })} />
						{isSessionNotificationVisible && <Notification text={translate('sessionUpdatedSuccessfully')} />}
						<AmbientMonitoringAlertModal
							feed={selectedFeed}
							display={isAmbientMonitoringAlertModalOpen}
							showForwardToNurses={showForwardToNurses}
							onForwardToNurses={feed => sendAutomaticAlert(feed)}
							onCloseClick={() => onAlertCloseClick(selectedFeed)}
						/>
					</Grid>
					<Alert display={error} fixed hideCloseButton message={error} variant='dark' />
				</>
			}
		</MainLayout>
	);
};

export default AlertCenter;
